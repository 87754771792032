var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('a-form',{ref:"formLogin",staticClass:"user-layout-login",attrs:{"id":"formLogin","form":_vm.form},on:{"submit":_vm.handleSubmit}},[(_vm.isLoginError)?_c('a-alert',{staticStyle:{"margin-bottom":"24px"},attrs:{"type":"error","showIcon":"","message":_vm.$t('user.login.message-invalid-credentials')}}):_vm._e(),_c('a-form-item',[_c('div',{staticClass:"allInputBox"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'username',
            {
              rules: [{ required: true, message: '请输入注册邮箱' }, { validator: _vm.handleUsernameOrEmail }],
              validateTrigger: 'change'
            }
          ]),expression:"[\n            'username',\n            {\n              rules: [{ required: true, message: '请输入注册邮箱' }, { validator: handleUsernameOrEmail }],\n              validateTrigger: 'change'\n            }\n          ]"}],attrs:{"type":"text","placeholder":"请输入注册邮箱"}})],1)]),_c('a-form-item',[_c('div',{staticClass:"allInputBox"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password', { rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'blur' }]),expression:"['password', { rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'blur' }]"}],attrs:{"type":_vm.passwordInputType,"placeholder":"请输入密码"}},[(_vm.passwordInputType !== 'password')?_c('AllowSvg',{staticClass:"svg-icon",attrs:{"slot":"suffix"},on:{"click":function($event){$event.stopPropagation();return _vm.changePasswordType.apply(null, arguments)}},slot:"suffix"}):_c('NoallowSvg',{staticClass:"svg-icon",attrs:{"slot":"suffix"},on:{"click":function($event){$event.stopPropagation();return _vm.changePasswordType.apply(null, arguments)}},slot:"suffix"})],1)],1)]),_c('a-form-item',[_c('div',{staticClass:"code-box allInputBox"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['code', { rules: [{ required: true, message: '请输入验证码' }], validateTrigger: 'blur' }]),expression:"['code', { rules: [{ required: true, message: '请输入验证码' }], validateTrigger: 'blur' }]"}],ref:"codeInput",attrs:{"autocomplete":"off","placeholder":"请输入验证码"}}),_c('img',{attrs:{"src":_vm.codeUrl,"alt":""},on:{"click":_vm.getCode}})],1)]),_c('a-button',{staticClass:"login-button",attrs:{"size":"large","type":"primary","htmlType":"submit","loading":_vm.state.loginBtn,"disabled":_vm.state.loginBtn}},[_vm._v("登录")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }